import React from 'react';

export default ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="151" height="31" viewBox="0 0 63.294 12.82">
    <g id="Group_27" data-name="Group 27" transform="translate(0 0)">
      <path id="Path_121" data-name="Path 121" d="M1092.512,1655.718v-1.357h.649a2.966,2.966,0,0,0,3-2.918c.017-1.412.009-2.825.007-4.236a2.965,2.965,0,0,0-2.62-2.9c-.154-.016-.31-.025-.464-.025q-2.021,0-4.041,0a2.971,2.971,0,0,0-2.98,2.974q0,2.081,0,4.162a2.954,2.954,0,0,0,2.691,2.929c.277.023.558,0,.845,0v1.371a4.743,4.743,0,0,1-2.069-.255,4.294,4.294,0,0,1-2.8-3.5,3.242,3.242,0,0,1-.035-.508q0-2.119,0-4.236a4.326,4.326,0,0,1,3.264-4.171,4.81,4.81,0,0,1,1.067-.129c1.4-.015,2.794-.007,4.191-.006a4.322,4.322,0,0,1,4.283,3.726,4.593,4.593,0,0,1,.044.613q.005,2.051,0,4.1a4.329,4.329,0,0,1-3.346,4.258,3.588,3.588,0,0,1-.666.1C1093.2,1655.73,1092.862,1655.718,1092.512,1655.718Z" transform="translate(-1084.691 -1642.909)" fill="#fff" />
      <path id="Path_122" data-name="Path 122" d="M1157.656,1647.253a2.783,2.783,0,0,1,.928-.788,2.364,2.364,0,0,1,1.06-.295c.981-.008,1.964-.028,2.943.006a2.7,2.7,0,0,1,2.468,2.264,3.427,3.427,0,0,1,.037.478q0,2.4,0,4.808v.13h-1.069v-.192q0-2.374,0-4.748a1.668,1.668,0,0,0-1.3-1.644,1.791,1.791,0,0,0-.386-.043q-1.232-.005-2.464,0a1.672,1.672,0,0,0-1.693,1.7q0,2.366,0,4.732v.2h-1.069v-4.932a1.673,1.673,0,0,0-1.7-1.7h-2.4a1.674,1.674,0,0,0-1.734,1.731v4.9h-1.059c0-.044-.01-.087-.01-.129,0-1.6-.005-3.195,0-4.793a2.718,2.718,0,0,1,2.16-2.719,1.962,1.962,0,0,1,.416-.052c.941,0,1.883-.021,2.824,0a2.586,2.586,0,0,1,1.942.967l.087.1A.15.15,0,0,0,1157.656,1647.253Z" transform="translate(-1101.801 -1643.757)" fill="#fff" />
      <path id="Path_123" data-name="Path 123" d="M1095.232,1650.183a2.015,2.015,0,0,1,.657,1.139,2.22,2.22,0,0,1-1.028,2.508,1.969,1.969,0,0,1-.99.26c-.411,0-.822,0-1.232,0h-.185v2.474h-1.018v-2.474c-.076,0-.134,0-.193,0-.48-.009-.965.02-1.439-.037a2.038,2.038,0,0,1-1.739-1.966q-.015-1.939,0-3.876a2.058,2.058,0,0,1,1.982-1.992q1.945-.025,3.891,0a2.078,2.078,0,0,1,2,2.053,2.39,2.39,0,0,1-.278,1.331C1095.539,1649.8,1095.384,1649.981,1095.232,1650.183Zm-6.172-.527a.354.354,0,0,0,.057.011c1.588,0,3.175.005,4.762,0a1.059,1.059,0,0,0,1.015-.794,2.407,2.407,0,0,0,.046-.579,1.087,1.087,0,0,0-1.079-1.082q-1.863,0-3.726,0a1.075,1.075,0,0,0-1.07.981C1089.038,1648.675,1089.06,1649.162,1089.06,1649.656Zm0,1.006c0,.468-.006.913,0,1.358a1.087,1.087,0,0,0,1.1,1.073c1.227,0,2.453,0,3.68,0a1.011,1.011,0,0,0,.982-.618,1.674,1.674,0,0,0,.114-.64,1.086,1.086,0,0,0-1.167-1.173h-4.715Z" transform="translate(-1085.57 -1643.771)" fill="#fff" />
      <path id="Path_124" data-name="Path 124" d="M1113.96,1650.195a2.021,2.021,0,0,1,.6.982,2.3,2.3,0,0,1-.729,2.481,1.925,1.925,0,0,1-1.178.428c-1.3.008-2.6.016-3.905,0a2.051,2.051,0,0,1-1.976-1.98q-.024-1.952,0-3.9a2.052,2.052,0,0,1,1.976-1.98q1.953-.026,3.9,0a2.071,2.071,0,0,1,1.99,1.987,2.378,2.378,0,0,1-.191,1.23C1114.317,1649.7,1114.132,1649.936,1113.96,1650.195Zm-6.193-.537a.659.659,0,0,0,.07.01c1.582,0,3.164.005,4.746,0a1.067,1.067,0,0,0,1.043-.888,2.644,2.644,0,0,0,.024-.478,1.088,1.088,0,0,0-1.088-1.09q-1.855,0-3.71,0a1.085,1.085,0,0,0-1.062.869,1.059,1.059,0,0,0-.024.208C1107.766,1648.742,1107.767,1649.2,1107.767,1649.658Zm0,1c0,.457,0,.9,0,1.335a1.089,1.089,0,0,0,1.1,1.1h3.694a1.057,1.057,0,0,0,.737-.286,1.269,1.269,0,0,0-.953-2.157c-1.415.038-2.833.011-4.25.011Z" transform="translate(-1090.456 -1643.771)" fill="#fff" />
      <path id="Path_125" data-name="Path 125" d="M1130.151,1646.166h1.307a2.717,2.717,0,0,1,2.652,2.324,3.083,3.083,0,0,1,.027.388q0,1.27,0,2.539a2.7,2.7,0,0,1-2.589,2.694c-.93.026-1.863.023-2.793,0a2.7,2.7,0,0,1-2.521-2.1,2.2,2.2,0,0,1-.063-.474c-.005-.946-.025-1.893,0-2.839a2.7,2.7,0,0,1,2.222-2.494,3.257,3.257,0,0,1,.478-.038C1129.3,1646.163,1129.725,1646.166,1130.151,1646.166Zm0,1.063h-1.186a1.679,1.679,0,0,0-1.74,1.742q0,1.172,0,2.343a1.681,1.681,0,0,0,1.743,1.74h2.388a1.681,1.681,0,0,0,1.717-1.721q0-1.194,0-2.388a1.68,1.68,0,0,0-1.72-1.716Z" transform="translate(-1095.521 -1643.759)" fill="#fff" />
      <path id="Path_126" data-name="Path 126" d="M1142.162,1646.165c.406,0,.811.01,1.217,0a2.722,2.722,0,0,1,2.763,2.708c0,.891.01,1.783-.008,2.674a2.716,2.716,0,0,1-2.25,2.531,3.342,3.342,0,0,1-.494.037c-.906,0-1.813.021-2.718-.01a2.706,2.706,0,0,1-2.472-2.264,2.749,2.749,0,0,1-.032-.358c0-.936-.02-1.873.007-2.808a2.683,2.683,0,0,1,2.233-2.464,12.206,12.206,0,0,1,1.378-.044C1141.912,1646.163,1142.037,1646.165,1142.162,1646.165Zm-.01,6.888h1.2a1.675,1.675,0,0,0,1.721-1.729v-2.358a1.673,1.673,0,0,0-1.741-1.739h-2.373a1.673,1.673,0,0,0-1.728,1.736q0,1.187,0,2.373a1.676,1.676,0,0,0,1.719,1.716Z" transform="translate(-1098.656 -1643.759)" fill="#fff" />
      <path id="Path_127" data-name="Path 127" d="M1123.945,1653.124v1.045a.273.273,0,0,1-.06.016c-.7,0-1.4.019-2.1-.007a2.705,2.705,0,0,1-2.5-2.2,3.325,3.325,0,0,1-.051-.582c0-1.591,0-3.183,0-4.774v-.191h1.072v4.931a1.675,1.675,0,0,0,1.766,1.758h1.873Z" transform="translate(-1093.713 -1643.83)" fill="#fff" />
    </g>
  </svg>

);
