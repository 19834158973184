// extracted by mini-css-extract-plugin
export var active = "contact-module--active--NYYWJ";
export var bigTittleWrapper = "contact-module--bigTittleWrapper--iG-wn";
export var biggerText = "contact-module--biggerText--gJhHl";
export var errorMessage = "contact-module--errorMessage--WSwbY";
export var form = "contact-module--form---ksz6";
export var formTitle = "contact-module--formTitle--9SAlm";
export var input = "contact-module--input--eswFR";
export var label = "contact-module--label--Rapk-";
export var section = "contact-module--section--40mPt";
export var teal = "contact-module--teal--OEz7X";