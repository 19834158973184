// extracted by mini-css-extract-plugin
export var address = "footer-module--address--3owb+";
export var bannerWrapper = "footer-module--bannerWrapper--aosO6";
export var footer = "footer-module--footer--fdIQ7";
export var footerInfo = "footer-module--footerInfo--pbFM6";
export var footerSection = "footer-module--footerSection--BruGP";
export var goUp = "footer-module--goUp--85ONs";
export var legalInfo = "footer-module--legalInfo--2DPUU";
export var logoWrapper = "footer-module--logoWrapper--ai6HI";
export var socialLinks = "footer-module--socialLinks--4zJ2J";
export var titleContainer = "footer-module--titleContainer--egVHG";
export var titleSmall = "footer-module--titleSmall--3+CW0";
export var upicon = "footer-module--upicon--f2Mkq";
export var upiconContainer = "footer-module--upiconContainer--Udigr";