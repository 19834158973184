import React from 'react';

export default ({ ...props }) => (
  <svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="45.542" height="45.542" viewBox="0 0 45.542 45.542">
    <path id="Path_48" data-name="Path 48" d="M33.215,0H12.328A12.342,12.342,0,0,0,0,12.328V33.215A12.342,12.342,0,0,0,12.328,45.542H33.215A12.342,12.342,0,0,0,45.542,33.215V12.328A12.342,12.342,0,0,0,33.215,0Zm9.658,33.215a9.669,9.669,0,0,1-9.658,9.658H12.328A9.669,9.669,0,0,1,2.67,33.215V12.328A9.669,9.669,0,0,1,12.328,2.67H33.215a9.669,9.669,0,0,1,9.658,9.658Zm0,0" fill="#214a79" />
    <path id="Path_49" data-name="Path 49" d="M128.457,116a12.453,12.453,0,1,0,12.453,12.453A12.467,12.467,0,0,0,128.457,116Zm0,22.235a9.783,9.783,0,1,1,9.783-9.783A9.794,9.794,0,0,1,128.457,138.239Zm0,0" transform="translate(-105.685 -105.685)" fill="#214a79" />
    <path id="Path_50" data-name="Path 50" d="M361.656,66.285a3.68,3.68,0,1,0,3.68,3.68A3.684,3.684,0,0,0,361.656,66.285Zm0,4.689a1.01,1.01,0,1,1,1.01-1.01A1.011,1.011,0,0,1,361.656,70.974Zm0,0" transform="translate(-326.134 -60.389)" fill="#214a79" />
  </svg>

);
