// extracted by mini-css-extract-plugin
export var disabled = "tools-module--disabled--d1N+A";
export var fadeEffect = "tools-module--fadeEffect--a11qM";
export var img = "tools-module--img--Qissi";
export var imgWrapper = "tools-module--imgWrapper--HZ4xr";
export var imgWrapperRectangularV = "tools-module--imgWrapperRectangularV--J6RYd";
export var navigationButton = "tools-module--navigationButton--bOzJM";
export var navigationWrapper = "tools-module--navigationWrapper--73bgp";
export var slideText = "tools-module--slideText--K559r";
export var swiperContainer = "tools-module--swiperContainer--NxA6S";
export var swiperWrapper = "tools-module--swiperWrapper--woVn3";
export var toolSlide = "tools-module--toolSlide--zqYYM";