import React from 'react';

export default ({ ...props }) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="21px"
    height="21px"
    viewBox="0 0 357 357"
    xmlSpace="preserve"
  >
    <g>
      <g id="close">
        <polygon
          fill="rgba(5, 63, 94, 1)"
          points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3
     214.2,178.5 		"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>

);
