import React from 'react';

export default ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="110" viewBox="0 0 52.487 46.976">
    <g id="Group_26" data-name="Group 26" transform="translate(0 0)">
      <path id="Path_110" data-name="Path 110" d="M1015.541,1673.47v2.446a1.521,1.521,0,0,1-.031.378,1.064,1.064,0,0,1-1.145.8,1.089,1.089,0,0,1-.973-.973c-.009-.095,0-.191,0-.286q0-2.382,0-4.765c0-.4.049-.343-.361-.346-.222,0-.445,0-.667,0a1.1,1.1,0,0,1-.463-.094,1.064,1.064,0,0,1-.614-1.2,1.051,1.051,0,0,1,.977-.852c.327-.014.656,0,.985-.006.129,0,.142-.014.142-.151,0-.614,0-1.228,0-1.842,0-.4-.007-.8,0-1.207a1.076,1.076,0,0,1,2.146-.105c.008.095.005.191.005.286v2.668c0,.085,0,.169,0,.254a.09.09,0,0,0,.1.1c.074,0,.148,0,.223,0,.254,0,.508,0,.762,0a1.086,1.086,0,0,1,1.026.876,1.072,1.072,0,0,1-1.007,1.271c-.253.014-.508,0-.762,0h-.222c-.073,0-.121.028-.121.109s0,.169,0,.254Z" transform="translate(-992.989 -1630.129)" fill="#fff" />
      <path id="Path_111" data-name="Path 111" d="M1033.209,1673.976a4.137,4.137,0,0,1,1.567-3.011,3.715,3.715,0,0,1,2.648-.875,3.616,3.616,0,0,1,1.46.4,1.068,1.068,0,0,1-.861,1.948c-.069-.027-.136-.058-.206-.083a1.626,1.626,0,0,0-1.622.225,2.034,2.034,0,0,0-.8,1.3,2.319,2.319,0,0,0,.162,1.458,2.111,2.111,0,0,0,.411.6,1.683,1.683,0,0,0,1.9.4,1.183,1.183,0,0,1,.68-.1,1.066,1.066,0,0,1,.337,2,3.1,3.1,0,0,1-.9.3,3.809,3.809,0,0,1-4.005-1.66A4.529,4.529,0,0,1,1033.209,1673.976Z" transform="translate(-998.721 -1631.64)" fill="#fff" />
      <path id="Path_112" data-name="Path 112" d="M1056.812,1670.705v5.082c0,.1,0,.19,0,.285a1.07,1.07,0,0,1-2.122.151,1.887,1.887,0,0,1-.023-.315q0-5.208,0-10.416a1.621,1.621,0,0,1,.044-.409,1.063,1.063,0,0,1,1.163-.771,1.051,1.051,0,0,1,.937.963c.007.106,0,.212,0,.318Z" transform="translate(-1004.325 -1630.131)" fill="#fff" />
      <path id="Path_113" data-name="Path 113" d="M1036.041,1665.937a4.268,4.268,0,0,1-3.319-1.529,1.365,1.365,0,0,1-.386-.749,1.02,1.02,0,0,1,.386-.983,1.051,1.051,0,0,1,1.5.171,2.537,2.537,0,0,0,.48.5,2.145,2.145,0,0,0,1.639.433,2.445,2.445,0,0,0,1.631-.94,1.059,1.059,0,0,1,1.82.25,1.048,1.048,0,0,1-.144,1.081,4.6,4.6,0,0,1-2.752,1.691A4.991,4.991,0,0,1,1036.041,1665.937Z" transform="translate(-998.49 -1629.64)" fill="#fff" />
      <path id="Path_114" data-name="Path 114" d="M1040.978,1633.008q0-5.258,0-10.516a4.956,4.956,0,0,0-.017-.6,4.269,4.269,0,0,0-2.507-3.462,4.447,4.447,0,0,0-1.849-.378q-5.051.008-10.1,0a5.472,5.472,0,0,0-.822.052,4.275,4.275,0,0,0-3.633,4.278q0,10.611,0,21.223a4.338,4.338,0,0,0,.318,1.672,4.217,4.217,0,0,0,2.27,2.323,4.005,4.005,0,0,0,1.667.332c1.006-.006,2.012,0,3.018,0,.085,0,.169,0,.254,0a.23.23,0,0,1,.188.1l.09.13a4.112,4.112,0,0,0,.774.878,4.273,4.273,0,0,0,6.286-.785c.075-.1.124-.241.223-.3a1.269,1.269,0,0,1,.4-.1,4.2,4.2,0,0,0,3.2-2.747,4.137,4.137,0,0,0,.253-1.524Q1040.974,1638.3,1040.978,1633.008Zm-2.156,10.534a2.741,2.741,0,0,1-.053.63,2.152,2.152,0,0,1-2.082,1.64c-.3,0-.593.01-.889,0a.335.335,0,0,0-.369.217,5.413,5.413,0,0,1-.683,1.067,2.712,2.712,0,0,1-.668.628,1.155,1.155,0,0,1-1.342-.005,3.4,3.4,0,0,1-.915-.958,5.721,5.721,0,0,1-.464-.794.234.234,0,0,0-.247-.153c-.179.007-.36,0-.54,0h-4.225a2.094,2.094,0,0,1-1.449-.552,2.029,2.029,0,0,1-.7-1.541c0-.117,0-.233,0-.349v-10.357q0-5.273,0-10.547a2.476,2.476,0,0,1,.136-.9,2.148,2.148,0,0,1,1.9-1.361l.318,0q4.971,0,9.944,0a3,3,0,0,1,.757.062,2.129,2.129,0,0,1,1.577,1.995c0,.075,0,.149,0,.223Q1038.823,1633.011,1038.823,1643.542Z" transform="translate(-995.805 -1618.049)" fill="#fff" />
      <path id="Path_115" data-name="Path 115" d="M995.054,1665.579c0-.106,0-.212-.008-.317a1.07,1.07,0,0,0-2.117-.073,2.515,2.515,0,0,0-.022.38q0,1.6,0,3.208a.712.712,0,0,1-.024.312c-.108.023-.18-.054-.262-.092a4.146,4.146,0,0,0-2.875-.291,4.253,4.253,0,0,0-3.207,3.892,4.258,4.258,0,0,0,5.329,4.356,4,4,0,0,0,.932-.394c.1-.054.1-.053.177.043a1.231,1.231,0,0,0,1.079.5,1.052,1.052,0,0,0,.994-.974c0-.1.006-.191.006-.286Q995.055,1670.709,995.054,1665.579Zm-4.262,9.375a2.114,2.114,0,1,1,2.115-2.114A2.117,2.117,0,0,1,990.792,1674.954Z" transform="translate(-986.53 -1630.131)" fill="#fff" />
      <path id="Path_116" data-name="Path 116" d="M1028.591,1673.5a4.059,4.059,0,0,0-.345-1.571,2.748,2.748,0,0,0-1.744-1.6,4.675,4.675,0,0,0-2-.194,4.753,4.753,0,0,0-2.454,1.024,1.128,1.128,0,0,0-.163.151,1.032,1.032,0,0,0-.186,1.126.993.993,0,0,0,.9.644,1.069,1.069,0,0,0,.768-.238,2.845,2.845,0,0,1,1.048-.511,2.29,2.29,0,0,1,1.4.026.719.719,0,0,1,.463.443.841.841,0,0,1,.081.321h-.341a11.458,11.458,0,0,0-2.277.136,4.158,4.158,0,0,0-1.419.528,2.364,2.364,0,0,0-1.072,2.743,2.6,2.6,0,0,0,1.492,1.788,3.205,3.205,0,0,0,1.2.274,5.577,5.577,0,0,0,2.443-.455c.038-.017.076-.036.115-.052a.1.1,0,0,1,.136.04,1.54,1.54,0,0,0,.117.15,1.057,1.057,0,0,0,1.223.244,1.036,1.036,0,0,0,.627-.986C1028.606,1676.191,1028.607,1674.847,1028.591,1673.5Zm-2.481,2.177a1.8,1.8,0,0,1-.929.627,3.326,3.326,0,0,1-1.156.14,1.318,1.318,0,0,1-.31-.054.538.538,0,0,1-.4-.409c-.047-.19-.026-.271.129-.378a1.683,1.683,0,0,1,.616-.236,3.458,3.458,0,0,1,.658-.081,12.425,12.425,0,0,1,1.359-.006c.062.006.124.014.192.021A.59.59,0,0,1,1026.11,1675.68Z" transform="translate(-995.573 -1631.646)" fill="#fff" />
      <path id="Path_117" data-name="Path 117" d="M1007.462,1674.337c0-.3.007-.593,0-.889a4.034,4.034,0,0,0-.447-1.737,2.663,2.663,0,0,0-1.45-1.309,4.338,4.338,0,0,0-1.138-.268,4.75,4.75,0,0,0-3.451.986,1.422,1.422,0,0,0-.193.165,1.032,1.032,0,0,0-.218,1.147,1,1,0,0,0,.93.655,1.052,1.052,0,0,0,.738-.233,2.894,2.894,0,0,1,1.046-.515,2.238,2.238,0,0,1,1.464.042.762.762,0,0,1,.471.76c-.443-.034-.887-.009-1.331-.007a6.783,6.783,0,0,0-1.323.14,3.873,3.873,0,0,0-1.489.6,2.343,2.343,0,0,0-.974,2.53,2.627,2.627,0,0,0,1.7,1.985,3.441,3.441,0,0,0,1.054.206,5.909,5.909,0,0,0,1.541-.164,5.434,5.434,0,0,0,1.068-.38c.052.069.088.12.127.168a1.064,1.064,0,0,0,1.893-.647c0-.052,0-.106,0-.158Q1007.468,1675.877,1007.462,1674.337Zm-2.478,1.34a1.782,1.782,0,0,1-.927.628,3.381,3.381,0,0,1-1.188.141,1.174,1.174,0,0,1-.368-.083.445.445,0,0,1-.237-.2c-.179-.344-.1-.487.167-.621a2.332,2.332,0,0,1,.881-.231,10.754,10.754,0,0,1,1.676-.025c.052.005.1.013.163.022A.632.632,0,0,1,1004.984,1675.677Z" transform="translate(-990.055 -1631.646)" fill="#fff" />
      <path id="Path_118" data-name="Path 118" d="M1048.892,1675.236a1.349,1.349,0,0,0,.44-.047,1.093,1.093,0,0,0,.745-1.037,4.209,4.209,0,0,0-.037-.537,4.023,4.023,0,0,0-1.231-2.542,3.473,3.473,0,0,0-3.793-.707,3.764,3.764,0,0,0-1.846,1.762,4.882,4.882,0,0,0-.189,4.144,3.479,3.479,0,0,0,1.859,1.987,3.708,3.708,0,0,0,1.094.307,4.4,4.4,0,0,0,2.333-.283,3.323,3.323,0,0,0,.692-.384,1.059,1.059,0,0,0-.649-1.92,1.235,1.235,0,0,0-.655.208,2.159,2.159,0,0,1-1.686.21,1.542,1.542,0,0,1-1.085-1.159h4.007Zm-3.745-2.231a1.378,1.378,0,0,1,1.625-.727,1.527,1.527,0,0,1,.919.8c-.094.008-.145.016-.2.016h-2.157a.92.92,0,0,1-.157-.013A.049.049,0,0,1,1045.147,1673Z" transform="translate(-1001.177 -1631.639)" fill="#fff" />
      <path id="Path_119" data-name="Path 119" d="M1037.034,1649.854a1.034,1.034,0,0,1,1.085,1.045,1.079,1.079,0,0,1-1.072,1.071,1.066,1.066,0,0,1-1.062-1.073A1.032,1.032,0,0,1,1037.034,1649.854Z" transform="translate(-999.446 -1626.356)" fill="#fff" />
      <path id="Path_120" data-name="Path 120" d="M1038.214,1624.857a1.08,1.08,0,0,0-.659-.938,1.356,1.356,0,0,0-.557-.1q-1.874,0-3.749,0c-1.429,0-2.859,0-4.288,0a1.116,1.116,0,0,0-1.15,1.168c.01,2.467,0,4.935,0,7.4h0v7.052c0,.138-.008.276,0,.413a1.065,1.065,0,0,0,1.088,1.052c.063,0,.127,0,.191,0h7.846c.074,0,.148,0,.222,0a1.056,1.056,0,0,0,1.019-.841,1.932,1.932,0,0,0,.036-.473q0-7.227,0-14.453A2.438,2.438,0,0,0,1038.214,1624.857Zm-2.147,13.438c0,.085,0,.169,0,.254-.006.194-.018.208-.226.211-.254,0-.508,0-.762,0h-4.73c-.412,0-.384.026-.384-.372v-12.064c0-.372-.019-.352.338-.352h5.43c.052,0,.106,0,.159,0,.148.007.169.025.176.163,0,.084,0,.169,0,.253V1638.3Z" transform="translate(-997.311 -1619.557)" fill="#fff" />
    </g>
  </svg>

);
