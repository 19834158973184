import React from 'react';
import * as styles from './icon.module.scss';

export default ({ ...props }) => (
  <svg id="icon-1" xmlns="http://www.w3.org/2000/svg" width="75.869" height="75.869" viewBox="0 0 75.869 75.869">
    <g id="Group_3" data-name="Group 3" transform="translate(0 28.507)">
      <g id="Group_2" data-name="Group 2">
        <path id="Path_56" data-name="Path 56" d="M74.755,237.511h-7.2V193.49a1.114,1.114,0,0,0-1.114-1.114H54.563a1.114,1.114,0,0,0-1.114,1.114v44.022H44.987V206.555a1.114,1.114,0,0,0-1.114-1.114H32a1.114,1.114,0,0,0-1.114,1.114v30.956H22.419V212.494a1.114,1.114,0,0,0-1.114-1.114H9.428a1.114,1.114,0,0,0-1.114,1.114v25.017h-7.2a1.114,1.114,0,0,0,0,2.227H74.755a1.114,1.114,0,1,0,0-2.227Zm-54.563,0H10.542v-23.9h9.651v23.9Zm22.568,0H33.109V207.669H42.76Zm22.568,0H55.677V194.6h9.651Z" transform="translate(0 -192.376)" fill="#347b7b" />
      </g>
    </g>
    <g id="Group_5" data-name="Group 5" transform="translate(1.188 0)">
      <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
        <path id="Path_57" data-name="Path 57" d="M79.143.873A3.487,3.487,0,0,0,76.4.027L66.9,1.215a3.489,3.489,0,0,0,.866,6.924l.775-.1A84.219,84.219,0,0,1,33.214,32.065,80.548,80.548,0,0,1,11.4,36.822,3.489,3.489,0,0,0,11.5,43.8l.107,0a85.345,85.345,0,0,0,23.782-5.1A93.579,93.579,0,0,0,56.271,28.2a1.114,1.114,0,0,0-1.245-1.847A91.334,91.334,0,0,1,34.646,36.6a82.9,82.9,0,0,1-23.1,4.972H11.5a1.262,1.262,0,0,1-.038-2.523,82.3,82.3,0,0,0,22.5-4.887,86.156,86.156,0,0,0,38.1-26.877,1.123,1.123,0,0,0-1.006-1.8l-3.573.447a1.262,1.262,0,1,1-.313-2.5l9.5-1.188A1.275,1.275,0,0,1,78.1,3.489v9.5a1.262,1.262,0,0,1-2.524,0V10.11a1.114,1.114,0,0,0-1.976-.7A86.1,86.1,0,0,1,59.52,23.112a1.114,1.114,0,0,0,1.361,1.763A88.255,88.255,0,0,0,73.348,13.148a3.489,3.489,0,0,0,6.975-.157v-9.5A3.492,3.492,0,0,0,79.143.873Z" transform="translate(-8.017 0)" fill="#347b7b" />
      </g>
    </g>
  </svg>

);
