// extracted by mini-css-extract-plugin
export var cardWrapper = "services-module--cardWrapper--gAjzX";
export var details = "services-module--details--wR-Ct";
export var first = "services-module--first--hUQmw";
export var fourth = "services-module--fourth--xSg8P";
export var img = "services-module--img--dGKEP";
export var mainWrapper = "services-module--mainWrapper--dyVvY";
export var overlay = "services-module--overlay--4mN4q";
export var overlay2 = "services-module--overlay2--OgkO1";
export var paragraphWrapper = "services-module--paragraphWrapper--W76DF";
export var second = "services-module--second---lfSy";
export var third = "services-module--third--6vGyE";
export var title = "services-module--title--ueAex";
export var title2 = "services-module--title2--oVRZ8";