import React from 'react';
import * as styles from './icon.module.scss';

export default ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 907.113 236.17">
    <path
      d="M-1757.14,484.2h-905.6c21.823-33.26,49.965-64.464,83.644-92.744,34.12-28.651,73.283-53.756,116.4-74.618a667.648,667.648,0,0,1,143.319-49.729,730.492,730.492,0,0,1,79.929-13.418,765.154,765.154,0,0,1,84.469-4.661c16.157,0,32.594.511,48.853,1.52,16.534,1.026,33.327,2.593,49.911,4.658,16.87,2.1,33.976,4.774,50.845,7.944,17.163,3.226,34.542,7.054,51.655,11.377a339.8,339.8,0,0,1,42.549,13.688,280.39,280.39,0,0,1,36.931,17.893,244.1,244.1,0,0,1,31.554,21.785,227.141,227.141,0,0,1,26.418,25.363,227.052,227.052,0,0,1,21.522,28.626,242.5,242.5,0,0,1,16.867,31.575,272.925,272.925,0,0,1,12.452,34.21A318.209,318.209,0,0,1-1757.14,484.2Z"
      transform="translate(2663.662 -248.535)"
      fill="#115173"
      stroke="rgba(0,0,0,0)"
      strokeWidth="1"
    />
  </svg>
);
