import React from 'react';
import * as styles from './icon.module.scss';

export default ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="68.557" height="82.268" viewBox="0 0 68.557 82.268">
    <g id="Secure" transform="translate(0 0)">
      <path id="Path_60" data-name="Path 60" d="M41.278,84.268a1.371,1.371,0,0,1-.686-.186l-13.862-8.09A39.87,39.87,0,0,1,7,41.653V14.34a1.371,1.371,0,0,1,.938-1.3L40.845,2.07a1.371,1.371,0,0,1,.867,0L74.619,13.039a1.371,1.371,0,0,1,.938,1.3V41.653A39.868,39.868,0,0,1,55.83,75.992l-13.866,8.09A1.371,1.371,0,0,1,41.278,84.268ZM9.742,15.329V41.655A37.115,37.115,0,0,0,28.107,73.622l13.171,7.687,13.171-7.687A37.115,37.115,0,0,0,72.814,41.653V15.327L41.278,4.816Z" transform="translate(-7 -2)" fill="#347b7b" />
      <path id="Path_61" data-name="Path 61" d="M39.794,76.353a1.371,1.371,0,0,1-.686-.186L28.016,69.7A34.407,34.407,0,0,1,11,40.087V16.723a1.371,1.371,0,0,1,.938-1.3L39.36,6.29a1.371,1.371,0,0,1,.867,0L67.65,15.422a1.371,1.371,0,0,1,.938,1.3V40.087A34.407,34.407,0,0,1,51.577,69.7L40.485,76.167a1.371,1.371,0,0,1-.691.186ZM13.742,17.71V40.087a31.654,31.654,0,0,0,15.65,27.239l10.4,6.069,10.4-6.069a31.654,31.654,0,0,0,15.65-27.239V17.71L39.794,9.036Z" transform="translate(-5.515 -0.434)" fill="#347b7b" />
      <path id="Path_62" data-name="Path 62" d="M33.113,34.485a1.371,1.371,0,0,1,1.371,1.371,1.371,1.371,0,0,0,2.742,0,4.113,4.113,0,0,0-2.742-3.861V30.371a1.371,1.371,0,1,0-2.742,0v1.623a4.1,4.1,0,0,0,1.371,7.974,1.371,1.371,0,1,1-1.371,1.371,1.371,1.371,0,1,0-2.742,0A4.113,4.113,0,0,0,31.742,45.2v1.623a1.371,1.371,0,1,0,2.742,0V45.2a4.1,4.1,0,0,0-1.371-7.975,1.371,1.371,0,1,1,0-2.742Z" transform="translate(1.165 8.021)" fill="#347b7b" />
      <path id="Path_63" data-name="Path 63" d="M47.051,30.454H45.68V23.6a9.6,9.6,0,1,0-19.2,0v6.856H25.113A4.113,4.113,0,0,0,21,34.567v19.2a4.113,4.113,0,0,0,4.113,4.113H47.051a4.113,4.113,0,0,0,4.113-4.113v-19.2A4.113,4.113,0,0,0,47.051,30.454ZM29.227,23.6a6.856,6.856,0,0,1,13.711,0v6.856H41.567V23.6a5.485,5.485,0,1,0-10.969,0v6.856H29.227Zm9.6,6.856H33.34V23.6a2.742,2.742,0,1,1,5.485,0Zm9.6,23.309a1.371,1.371,0,0,1-1.371,1.371H25.113a1.371,1.371,0,0,1-1.371-1.371v-19.2A1.371,1.371,0,0,1,25.113,33.2H47.051a1.371,1.371,0,0,1,1.371,1.371Z" transform="translate(-1.804 2.454)" fill="#347b7b" />
    </g>
  </svg>

);
