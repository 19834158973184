// extracted by mini-css-extract-plugin
export var icon = "icon-module--icon--bogeP";
export var w10 = "icon-module--w10--67ReK";
export var w12 = "icon-module--w12--fUcM7";
export var w14 = "icon-module--w14--u7Vbs";
export var w16 = "icon-module--w16--uSi70";
export var w18 = "icon-module--w18--KiHf6";
export var w20 = "icon-module--w20--GWup6";
export var w22 = "icon-module--w22--7QhfD";
export var w24 = "icon-module--w24--Mvb3w";
export var w26 = "icon-module--w26--Hxnb7";
export var w28 = "icon-module--w28--bXu43";
export var w30 = "icon-module--w30--16t6U";
export var w32 = "icon-module--w32--M+Qhs";
export var w6 = "icon-module--w6--ONaFj";
export var w9 = "icon-module--w9--2sswB";