// extracted by mini-css-extract-plugin
export var active = "header-module--active--zPE0T";
export var buttonWrapper = "header-module--buttonWrapper--b-5LE";
export var closed = "header-module--closed--gtSXE";
export var da = "header-module--da--Jqj7f";
export var dropdownElement = "header-module--dropdownElement--Wsa-7";
export var dropdownText = "header-module--dropdownText--2Jsai";
export var header = "header-module--header--K1OV4";
export var iconsWrapper = "header-module--iconsWrapper--9QWnP";
export var languageDropdown = "header-module--languageDropdown--Vaxm0";
export var languagedropdownWrapper = "header-module--languagedropdownWrapper--g9vrq";
export var line = "header-module--line--+VZKH";
export var link = "header-module--link--m33Es";
export var linksWrapper = "header-module--linksWrapper--g17N+";
export var navBackground = "header-module--navBackground--kDKtZ";
export var navWrapper = "header-module--navWrapper--Qm6js";
export var navWrapperMdSm = "header-module--navWrapperMdSm--dyU+F";
export var opened = "header-module--opened--LsaJB";
export var socialIconsWrapper = "header-module--socialIconsWrapper--0tAHY";
export var wrapper = "header-module--wrapper--3t3EH";