import React from 'react';

export default ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="41" viewBox="0 0 50.325 17.484">
    <g id="Group_29" data-name="Group 29" transform="translate(0 0)">
      <path id="Path_139" data-name="Path 139" d="M1251.567,1653.609c-.335,0-.669.012-1,0-.4-.017-.474-.123-.468-.531,0-.12-.057-.305-.142-.346a.494.494,0,0,0-.4.047,4.51,4.51,0,0,0-.492.42,2.893,2.893,0,0,1-3.238.223,2.1,2.1,0,0,1-.949-1.436,5.056,5.056,0,0,1,.107-3.13,3.9,3.9,0,0,1,3.431-2.542,7.852,7.852,0,0,0,1.208-.247c.366-.1.487-.279.391-.638a4,4,0,0,0-.451-1.105,1.144,1.144,0,0,0-1.517-.365,6.741,6.741,0,0,0-1.081.848.9.9,0,0,1-1.073.21.843.843,0,0,1-.394-1.085,2.155,2.155,0,0,1,.538-.749,2.972,2.972,0,0,1,1.7-.683,6.489,6.489,0,0,1,2.6.237,2.92,2.92,0,0,1,2.042,2.439,9.274,9.274,0,0,1,.089,2.521c-.1,1.264-.157,2.531-.225,3.8a1.491,1.491,0,0,0,.4,1.017,1.977,1.977,0,0,1,.324.551c.115.322-.048.542-.391.556s-.668,0-1,0Zm-1.482-5.543c0-.371-.161-.56-.631-.522a1.657,1.657,0,0,0-.744.228,3.233,3.233,0,0,0-1.159,1.272,2.812,2.812,0,0,0-.076,2.581.96.96,0,0,0,.935.644,1.439,1.439,0,0,0,1.436-1.31C1249.955,1650.034,1250,1649.1,1250.085,1648.066Z" transform="translate(-1209.903 -1639.435)" fill="#fff" />
      <path id="Path_140" data-name="Path 140" d="M1228.337,1646.507c0,2.359.009,4.719-.007,7.078a1.959,1.959,0,0,0,.427,1.251,1.664,1.664,0,0,1,.259.457c.123.358-.058.584-.439.553-.724-.058-1.447-.134-2.171-.194-.459-.037-.924-.032-1.381-.091-.556-.071-.666-.348-.289-.759a2.327,2.327,0,0,0,.6-1.662c.026-4.06.075-8.12.124-12.179a.779.779,0,0,0-.438-.764c-.631-.348-.647-.519-.075-.955a3.852,3.852,0,0,1,2.829-.853c.44.049.605.263.615.717.01.531,0,1.062,0,1.592q0,2.906,0,5.81Z" transform="translate(-1204.636 -1638.365)" fill="#fff" />
      <path id="Path_141" data-name="Path 141" d="M1222.048,1645.334a3.4,3.4,0,0,1-2.926,3.506,9.181,9.181,0,0,1-1.339.184c-.421.04-.563.162-.585.577a2.363,2.363,0,0,0,1.839,2.531,1.915,1.915,0,0,0,1.718-.58c.288-.25.531-.554.819-.805.109-.1.368-.194.419-.145a.644.644,0,0,1,.187.47,2.606,2.606,0,0,1-1.015,1.732,3.774,3.774,0,0,1-5.871-1.482,9.782,9.782,0,0,1-.573-4.4,6.13,6.13,0,0,1,1.024-3.121,3.8,3.8,0,0,1,2.206-1.666A3.315,3.315,0,0,1,1222.048,1645.334Zm-5.017,1.512a3.085,3.085,0,0,0,.029.4c.066.355.218.471.579.416a2.65,2.65,0,0,0,.593-.167,2.563,2.563,0,0,0,1.5-3.355,1.105,1.105,0,0,0-.995-.763,1.051,1.051,0,0,0-1,.659A6.275,6.275,0,0,0,1217.031,1646.846Z" transform="translate(-1202.068 -1639.324)" fill="#fff" />
      <path id="Path_142" data-name="Path 142" d="M1263.1,1644.762a3.012,3.012,0,0,1-.01.617.619.619,0,0,1-.248.4c-.192.089-.354-.064-.438-.248-.109-.241-.2-.491-.305-.733a1.854,1.854,0,0,0-2.159-1.051,1.4,1.4,0,0,0-.979,1.075,1.167,1.167,0,0,0,.6,1.38c.575.3,1.177.537,1.775.785a2.635,2.635,0,0,1,1.611,1.5,3.915,3.915,0,0,1-3.161,5.4,3.324,3.324,0,0,1-3.183-1.26,1.724,1.724,0,0,1-.389-1.541,1.16,1.16,0,0,1,.136-.324.444.444,0,0,1,.821-.027,2.481,2.481,0,0,1,.271.551,2.06,2.06,0,0,0,2.255,1.362,1.285,1.285,0,0,0,1.037-.972,1.108,1.108,0,0,0-.366-1.3,7,7,0,0,0-1.189-.687c-.465-.228-.971-.377-1.426-.624a2.112,2.112,0,0,1-1.125-1.88,6.55,6.55,0,0,1,.2-2.1,3.489,3.489,0,0,1,3.213-2.517,4.934,4.934,0,0,1,2,.291,1.33,1.33,0,0,1,1.063,1.546c0,.118,0,.236,0,.354Z" transform="translate(-1212.901 -1639.462)" fill="#fff" />
      <path id="Path_143" data-name="Path 143" d="M1237.739,1650.246c0-1.268,0-2.535,0-3.8,0-.409-.1-.532-.506-.549-.471-.019-.775-.236-.787-.628-.015-.481.091-.84.609-.972a7.337,7.337,0,0,0,.821-.223,1.855,1.855,0,0,0,1.082-1.572q.208-1.038.406-2.079c.083-.434.325-.669.672-.641a.644.644,0,0,1,.624.72c-.008.7-.057,1.393-.085,2.09-.015.383-.038.766-.032,1.148.006.424.219.62.643.641a6.588,6.588,0,0,1,.848.06.644.644,0,0,1,.582.7c.017.459-.166.776-.547.863a2.447,2.447,0,0,1-.728.041c-.612-.05-.838.137-.852.751q-.072,3.065-.141,6.131c-.012.527.207.75.732.786a4.791,4.791,0,0,1,.872.121.607.607,0,0,1,.454.727c-.038.378-.188.55-.576.58a8.612,8.612,0,0,1-.883-.018c-.816-.022-1.631-.07-2.446-.059a.874.874,0,0,1-.95-.981c.016-1.012.054-2.024.079-3.036.007-.265,0-.531,0-.8Z" transform="translate(-1207.752 -1638.734)" fill="#fff" />
      <path id="Path_144" data-name="Path 144" d="M1207.575,1653.84a3.311,3.311,0,0,1-2.612-.989,2.277,2.277,0,0,1-.588-2.042c.04-.209.126-.408.367-.445.265-.041.409.131.525.344a11.373,11.373,0,0,0,.714,1.25,1.436,1.436,0,0,0,1.436.627,1.272,1.272,0,0,0,1.04-.932,1.183,1.183,0,0,0-.474-1.453,8.261,8.261,0,0,0-1.61-.777,2.721,2.721,0,0,1-1.854-2.138,4.082,4.082,0,0,1,.5-3.088,2.579,2.579,0,0,1,1.808-1.294,4.087,4.087,0,0,1,2.779.313,1.316,1.316,0,0,1,.768,1.047,6.021,6.021,0,0,1,0,1.253c-.036.326-.273.384-.486.129a3.733,3.733,0,0,1-.461-.747,1.686,1.686,0,0,0-1.183-1.013,1.234,1.234,0,0,0-1.563.8,1.4,1.4,0,0,0,.766,1.787c.562.231,1.138.428,1.7.664a2.5,2.5,0,0,1,1.543,1.912,4.842,4.842,0,0,1-.082,2.559A3.112,3.112,0,0,1,1207.575,1653.84Z" transform="translate(-1199.364 -1639.523)" fill="#fff" />
      <path id="Path_145" data-name="Path 145" d="M1234.821,1650.951c0,1.278.009,2.557-.005,3.835a1.894,1.894,0,0,0,.39,1.177,1.876,1.876,0,0,1,.27.517c.1.3-.044.491-.361.477-.51-.023-1.017-.085-1.526-.122-.549-.039-1.1-.067-1.648-.1a1.512,1.512,0,0,1-.319-.045c-.361-.106-.474-.346-.222-.608a2.817,2.817,0,0,0,.644-2.159c-.006-1.721.022-3.442.055-5.163a1.206,1.206,0,0,0-.652-1.268c-.347-.174-.364-.44-.052-.669a4.144,4.144,0,0,1,2.914-.807.522.522,0,0,1,.489.573c.034.756.038,1.512.046,2.27.007.7,0,1.4,0,2.094Z" transform="translate(-1206.375 -1640.358)" fill="#fff" />
      <path id="Path_146" data-name="Path 146" d="M1201.256,1651.2c0,1.357-.024,2.715.017,4.071a3.168,3.168,0,0,0,.36,1c.077.188.211.356.272.548.1.309-.036.482-.362.465-.558-.029-1.116-.089-1.674-.128s-1.1-.047-1.646-.107-.661-.334-.316-.741a2.236,2.236,0,0,0,.556-1.534c-.008-1.554.025-3.108.039-4.661,0-.491.012-.983,0-1.473a.8.8,0,0,0-.476-.725,1.307,1.307,0,0,1-.128-.074c-.361-.238-.39-.46-.042-.71a4.26,4.26,0,0,1,2.8-.8c.389.022.583.267.59.71.015.924.011,1.849.014,2.774,0,.462,0,.924,0,1.387Z" transform="translate(-1197.609 -1640.444)" fill="#fff" />
      <path id="Path_147" data-name="Path 147" d="M1233.193,1639.283a1.494,1.494,0,0,1,1.5,1.491,1.495,1.495,0,1,1-2.988.038A1.488,1.488,0,0,1,1233.193,1639.283Z" transform="translate(-1206.514 -1638.605)" fill="#fff" />
      <path id="Path_148" data-name="Path 148" d="M1199.647,1639.614a1.525,1.525,0,0,1-.02,3.049,1.5,1.5,0,0,1-1.473-1.552A1.476,1.476,0,0,1,1199.647,1639.614Z" transform="translate(-1197.751 -1638.691)" fill="#fff" />
    </g>
  </svg>

);
