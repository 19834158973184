// extracted by mini-css-extract-plugin
export var active = "about-module--active--xvWrh";
export var animatedLine = "about-module--animatedLine--Bfc57";
export var bannerWrapper = "about-module--bannerWrapper--gdz+Q";
export var cardWrapper = "about-module--cardWrapper--DG7ZG";
export var container = "about-module--container--tfdoi";
export var content = "about-module--content--LTnBp";
export var first = "about-module--first--PMwvy";
export var fourth = "about-module--fourth--U-bA5";
export var iconWrapper = "about-module--iconWrapper--yH5TI";
export var paragraphWrapper = "about-module--paragraphWrapper--apIsH";
export var second = "about-module--second--31Za9";
export var teal = "about-module--teal--hmHTO";
export var third = "about-module--third--dY5pg";
export var titleContainer = "about-module--titleContainer--m8bNy";